import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
import { PostUpdateTitle } from '../../components/post-update-title/post-update-title';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Menus, tooltips, date pickers, or dialogs – popover content is everywhere on the web. Despite how common these
components are, there are no native HTML elements for most of them (except for `}<a parentName="p" {...{
        "href": "/native-dialog-element.en/"
      }}>{`dialogs`}</a>{`).
And their implementation requires quite a lot of JavaScript.`}</p>
    <p>{`All of this will change with the new `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Popover_API"
      }}>{`Popover API`}</a>{`
combined with `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-anchor-position-1/"
      }}>{`CSS Anchor Positioning`}</a>{`. They provide developers with a
powerful mechanism for displaying popover content on top of other page content.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "63%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIFAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABq8eayzhg/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxIRIf/aAAgBAQABBQLwxg27eixDybLR2G//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEv/aAAgBAwEBPwGT/8QAFREBAQAAAAAAAAAAAAAAAAAAEBL/2gAIAQIBAT8Bo//EABkQAAMAAwAAAAAAAAAAAAAAAAABEQIxMv/aAAgBAQAGPwId5GXZB3Cn/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFBUf/aAAgBAQABPyGshGAEcYyoZES/HI/BHIuiQ1ds/9oADAMBAAIAAwAAABATD//EABgRAQEAAwAAAAAAAAAAAAAAAAEAESEx/9oACAEDAQE/EErc1m//xAAXEQADAQAAAAAAAAAAAAAAAAAAESEx/9oACAECAQE/EGNqP//EAB0QAAICAgMBAAAAAAAAAAAAAAERADEhcUFRYfD/2gAIAQEAAT8QYJYLRj8qyoW8/eRTUXKnS4Ngbh1tlTg5ZQWZT//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A skateboarder in mid air jump.",
          "title": "A skateboarder in mid air jump.",
          "src": "/static/eb944493c38cda4c2efba07a52c43df1/e5166/pexels-zachary-debottis-skateboarder-jump.jpg",
          "srcSet": ["/static/eb944493c38cda4c2efba07a52c43df1/f93b5/pexels-zachary-debottis-skateboarder-jump.jpg 300w", "/static/eb944493c38cda4c2efba07a52c43df1/b4294/pexels-zachary-debottis-skateboarder-jump.jpg 600w", "/static/eb944493c38cda4c2efba07a52c43df1/e5166/pexels-zachary-debottis-skateboarder-jump.jpg 1200w", "/static/eb944493c38cda4c2efba07a52c43df1/b17f8/pexels-zachary-debottis-skateboarder-jump.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Zachary DeBottis / pexels.com`}</em></p>
    <p>{`I've prepared two demos with examples of popover content. We'll take an in-depth look at the technical aspects as
well as the accessibility of the new features.`}</p>
    <h2>{`What can we do with it?`}</h2>
    <p>{`I love the saying: Show, don't tell! That's why I've created two demos to illustrate the capabilities of the new API.`}</p>
    <p>{`At the moment, the examples only work in `}<a parentName="p" {...{
        "href": "https://www.google.com/intl/en/chrome/canary/"
      }}>{`Chrome Canary`}</a>{`, the nightly build
for developers. Open the browser and access the `}<InlineCode mdxType="InlineCode">{`chrome://flags`}</InlineCode>{` page to enable the experimental web
platform features.`}</p>
    <p>{`Warning: The anchor positioning feature is still highly experimental. Therefore, the demos might break
if the specification changes.`}</p>
    <h3>{`Example 1: Custom Tooltip`}</h3>
    <p>{`Standard tooltips created with the `}<InlineCode mdxType="InlineCode">{`title`}</InlineCode>{` attribute are pretty bland. So, I've tried my hand at
a fancy `}<a parentName="p" {...{
        "href": "https://codepen.io/alexlehner86/pen/dyQRJBv"
      }}>{`custom tooltip`}</a>{`. Click on the info icon button next to the heading:`}</p>
    <iframe title="Accessible Tooltip with Popover API" src="https://codepen.io/alexlehner86/embed/dyQRJBv?default-tab=result" loading="lazy">
    See the Pen <a href="https://codepen.io/alexlehner86/pen/dyQRJBv">
    Accessible Tooltip with Popover API</a> by Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`I did my best to make the tooltip accessible, following the `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/tooltip/"
      }}>{`Tooltip Pattern`}</a>{`
defined in the ARIA Authoring Practices Guide. Unfortunately, the desing pattern is still work in progress and there's no
consensus yet. Also, the Popover API doesn't account for hover and focus yet.`}</p>
    <h3>{`Example 2: Menu with a Set of Actions`}</h3>
    <p>{`I also created a `}<a parentName="p" {...{
        "href": "https://codepen.io/alexlehner86/pen/rNQmERq"
      }}>{`demo`}</a>{` for an accessible menu. Each item in the list of checked out
books contains a menu button that toggles a menu panel with a set of actions for each book:`}</p>
    <iframe title="Accessible Menu with Popover API" src="https://codepen.io/alexlehner86/embed/rNQmERq?default-tab=result" loading="lazy">
    See the Pen <a href="https://codepen.io/alexlehner86/pen/rNQmERq">
    Accessible Menu with Popover API</a> by Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`My implementation follows the `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/menubar/"
      }}>{`Menu and Menubar Pattern`}</a>{` defined in the ARIA
Authoring Practices Guide. I've used the appropriate ARIA roles and properties and also implemented (most of) the recommended
keyboard interaction.`}</p>
    <p>{`Now let's examine the different building blocks that I used to create these components.`}</p>
    <h2>{`The magical `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{` attribute`}</h2>
    <p>{`This HTML attribute turns any element into popover content. The
`}<a parentName="p" {...{
        "href": "https://html.spec.whatwg.org/multipage/popover.html#the-popover-attribute"
      }}>{`HTML specification`}</a>{` states:`}</p>
    <blockquote>
      <p parentName="blockquote">{`All HTML elements may have the `}<BoldText mdxType="BoldText">{`popover`}</BoldText>{` content attribute set. When specified, the element won't be
rendered until it becomes shown, at which point it will be rendered on top of other page content.`}</p>
    </blockquote>
    <p>{`This popover content is always non-modal, meaning that the rest of the page can be interacted with while the popover is open.
If you need to display a modal popover (e.g., a confirmation dialog), you should use the `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{`
element.`}</p>
    <h2>{`How to create a popover`}</h2>
    <p>{`To create a popover, you add the `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{` attribute to an element and also define
an `}<InlineCode mdxType="InlineCode">{`id`}</InlineCode>{`. Next, you use the `}<InlineCode mdxType="InlineCode">{`popovertarget`}</InlineCode>{` attribute with the value of the
popover's id on the element that opens the popover. Here's an example:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<button popovertarget="popover-1">
    Info
</button>
<div id="popover-1" popover>
    Hi, I'm a popover!
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`That's it! You just created a popover with a few lines of HTML and zero JavaScript. Awesome!`}</p>
    <h3>{`Built-in features of the Popover API`}</h3>
    <p>{`When using the `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{` attribute, you'll get all of the following for free:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Placement in the top layer:`}</strong>{` Popover content will automatically appear on
the `}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Glossary/Top_layer"
        }}>{`top layer`}</a>{`, a separate layer that sits on top of all
other layers displayed in a web document. No more worrying about the DOM order or z-index.`}</li>
      <li parentName="ul"><strong parentName="li">{`Light-dismiss:`}</strong>{` Clicking outside of the popover area will close the popover and return focus to the
trigger element.`}</li>
      <li parentName="ul"><strong parentName="li">{`Accessible keyboard interaction:`}</strong>{` When the popover is open, you can hit the `}<InlineCode mdxType="InlineCode">{`ESC`}</InlineCode>{` key to
close the popover and return focus to the trigger element.`}</li>
      <li parentName="ul"><strong parentName="li">{`Semantic relationship:`}</strong>{` Connecting a popover element to a toggle button will also create a semantic relationship
for assistive technologies. Think `}<InlineCode mdxType="InlineCode">{`aria-controls`}</InlineCode>{` and `}<InlineCode mdxType="InlineCode">{`aria-expanded`}</InlineCode>{`
per default.`}</li>
    </ul>
    <h3>{`More options and styling`}</h3>
    <p>{`By default, when you create a popover with a `}<InlineCode mdxType="InlineCode">{`popovertarget`}</InlineCode>{`, the trigger button will toggle it open
and closed. Alternatively, you can also set an explicit value for the `}<InlineCode mdxType="InlineCode">{`popovertargetaction`}</InlineCode>{` attribute:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`"hide"`}</strong>{`: The button will hide a shown popover. This can be useful if you need a close button inside of the popover.`}</li>
      <li parentName="ul"><strong parentName="li">{`"show"`}</strong>{`: The button will show a hidden popover.`}</li>
      <li parentName="ul"><strong parentName="li">{`"toggle"`}</strong>{`: The button will toggle a popover between showing and hidden. This is the default behavior.`}</li>
    </ul>
    <p>{`Furthermore, the `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{` attribute is actually a shortcut for `}<InlineCode mdxType="InlineCode">{`popover="auto"`}</InlineCode>{`.
This standard type of popover will force close other auto popovers. It can be dismissed via light-dismiss or a close button.`}</p>
    <p>{`With `}<InlineCode mdxType="InlineCode">{`popover="manual"`}</InlineCode>{` you can create a manual popover. These popovers don't close via light-dismiss
and they do not force close any other popovers. They must be closed via an explicit close action. A possible use case for manual
popovers are `}<a parentName="p" {...{
        "href": "https://m2.material.io/components/snackbars"
      }}>{`alert snackbars`}</a>{` that should be confirmed by the user.`}</p>
    <p>{`Popovers also include useful CSS features: The `}<InlineCode mdxType="InlineCode">{`::backdrop`}</InlineCode>{` pseudo-element allows effects to be added to
the page content behind the popover. You can use the `}<InlineCode mdxType="InlineCode">{`:popover-open`}</InlineCode>{` pseudo-class to style popover
elements when they are showing.`}</p>
    <h2>{`CSS Anchor Positioning`}</h2>
    <p>{`If you've made it this far, you might be wondering: What about the position of the popover on the page? To position our popover
content relative to other elements, we actually need another new, experimental feature: Anchor Positioning.`}</p>
    <p>{`This feature is defined in the CSS Module `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-anchor-position-1/"
      }}>{`CSS Anchor Positioning`}</a>{`. Right now, the
document is still a `}<ItalicText mdxType="ItalicText">{`“Working Draft”`}</ItalicText>{`, but Chrome already offers the feature behind the
experimental web platform features flag. The document states:`}</p>
    <blockquote>
      <p parentName="blockquote">{`This specification defines `}<BoldText mdxType="BoldText">{`'anchor positioning'`}</BoldText>{`, where a positioned element can size and position itself
relative to one or more "anchor elements" elsewhere on the page.`}</p>
    </blockquote>
    <p>{`In my examples above, I've used anchor positioning to place the tooltip and the menu panels next to their trigger buttons.
Let's find out how to set up anchors and use them in our CSS styles.`}</p>
    <h3>{`How to define an anchor element`}</h3>
    <p>{`There are two different ways to set up anchors. You can turn an HTML element into an anchor by giving it
an `}<InlineCode mdxType="InlineCode">{`id`}</InlineCode>{`, and then use the `}<InlineCode mdxType="InlineCode">{`anchor`}</InlineCode>{` attribute to tether another element to your
anchor. Here's an example in combination with a popover:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<button id="toggle-btn-1" popovertarget="popover-1" class="toggle-btn">
    Info
</button>
<div id="popover-1" popover anchor="toggle-btn-1">
    Hi, I'm a popover!
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`As an alternative, you can use the CSS property `}<InlineCode mdxType="InlineCode">{`anchor-name`}</InlineCode>{` to set up an anchor. It accepts a
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/dashed-ident"
      }}>{`dashed-ident`}</a>{` value like `}<InlineCode mdxType="InlineCode">{`--toggle-btn`}</InlineCode>{`.
Next, you use the `}<InlineCode mdxType="InlineCode">{`position-anchor`}</InlineCode>{` property to tether another element to your anchor.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`button.toggle-btn {
    anchor-name: --toggle-btn;
}
div[popover] {
    position-anchor: --toggle-btn;
}`}</code>{`
        `}</deckgo-highlight-code>
    <h3>{`Position content with the `}<InlineCode mdxType="InlineCode">{`anchor()`}</InlineCode>{` function`}</h3>
    <p>{`Alright, so far you've set up an HTML element as an anchor and tethered your popover to it. Now you can use
the `}<InlineCode mdxType="InlineCode">{`anchor()`}</InlineCode>{` function to position the popover. The function has three arguments:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Anchor element`}</strong>{`: This value specifies how to find the anchor element. You can specify an explicit anchor name with
a dashed-ident value. Or, you can omit the value to use the implicit anchor that was defined through
the `}<InlineCode mdxType="InlineCode">{`anchor`}</InlineCode>{` attribute or the `}<InlineCode mdxType="InlineCode">{`position-anchor`}</InlineCode>{` CSS property.`}</li>
      <li parentName="ul"><strong parentName="li">{`Anchor side`}</strong>{`: This value refers to the position of the corresponding side of the target anchor element. For
example: `}<InlineCode mdxType="InlineCode">{`bottom`}</InlineCode>{` or `}<InlineCode mdxType="InlineCode">{`left`}</InlineCode>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Fallback (optional)`}</strong>{`: A length or percentage value that serves as a fallback in case of an invalid anchor function.`}</li>
    </ul>
    <p>{`Here's an example that shows how to place a tooltip below its toggle button and center the content horizontally:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`[role="tooltip"][popover] {
    position: absolute;
    position-anchor: --toggle-btn;
    top: anchor(bottom);
    left: anchor(center);
    translate: -50% 0;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`The specification includes even more features like the `}<InlineCode mdxType="InlineCode">{`anchor-scroll`}</InlineCode>{` property and
the `}<InlineCode mdxType="InlineCode">{`anchor-size()`}</InlineCode>{` function. But this blog post is already long enough, so I'll skip them for now.`}</p>
    <h2>{`Accessibility of Popovers`}</h2>
    <p>{`How accessible are popovers? As my examples above show, you can build user-friendly, accessible components with the new
Popover API. But that doesn't happen by itself.`}</p>
    <h3>{`Define the appropriate semantic role`}</h3>
    <p>{`Using the `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{` attribute can definitely help your website's accessibility. For example, with
the ability to close the popover via the `}<InlineCode mdxType="InlineCode">{`ESC`}</InlineCode>{` key. Or, through the semantic relationship between the
toggle button and the popover created by the `}<InlineCode mdxType="InlineCode">{`popovertarget`}</InlineCode>{` attribute.`}</p>
    <p>{`On the other hand, the `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{` attribute doesn't define any implicit role for the HTML element it
is used on. This means, you'll have to think about your specific use case and choose the correct native HTML element or
assign the appropriate `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles"
      }}>{`ARIA role`}</a>{`
(like `}<InlineCode mdxType="InlineCode">{`tooltip`}</InlineCode>{` or `}<InlineCode mdxType="InlineCode">{`menu`}</InlineCode>{`) yourself.`}</p>
    <h3>{`Screen Reader Support`}</h3>
    <p>{`How do screen reader users experience the interaction with web content built with the Popover API? Due to the limited
browser support for the new feature, I only tested my demos with the following scenarios:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.nvaccess.org/download/"
        }}>{`NVDA`}</a>{` 2023.1, Windows 11, Google Chrome Chanary 117.0.5876.0`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://support.google.com/accessibility/android/topic/10601571?hl=en"
        }}>{`TalkBack`}</a>{`, Samsung Galaxy S20, Android 13,
Google Chrome Chanary 117.0.5876.0`}</li>
    </ol>
    <p>{`Both demos worked great: I was able to navigate the tooltip and menus with the keyboard while NVDA
announced the semantic roles and current status of the controls.`}</p>
    <p>{`The interaction on my smartphone was also smooth: With TalkBack activated, I navigated the pages using
`}<a parentName="p" {...{
        "href": "https://support.google.com/accessibility/android/answer/6006598?hl=en"
      }}>{`swipe gestures`}</a>{` while the screen reader informed
me about the content, semantic roles and current status of the controls.`}</p>
    <p>{`This makes me really optimistic about the future of the web! I plan on doing more tests with other browsers and
screen readers as soon as the Popover API has better support.`}</p>
    <h2>{`Browser Support`}</h2>
    <p>{`When can we start using the Popover API and CSS Anchor Positioning in production? Unfortunately, it's going to take some
time until we have cross-browser support for them.`}</p>
    <p>{`According to `}<a parentName="p" {...{
        "href": "https://caniuse.com/mdn-api_htmlelement_popover"
      }}>{`caniuse.com`}</a>{`, the `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{` attribute
is already supported by Chrome and Edge. The `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/safari-technology-preview-release-notes/stp-release-170"
      }}>{`Safari 17 Technology Preview`}</a>{`
promises support for the attribute and `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Mozilla/Firefox/Experimental_features"
      }}>{`Firefox`}</a>{`
lists it as an experimental feature (behind the flag `}<InlineCode mdxType="InlineCode">{`dom.element.popover.enabled`}</InlineCode>{`).`}</p>
    <p>{`Cross-browser support for `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-anchor-position-1/"
      }}>{`CSS Anchor Positioning`}</a>{` will take even longer. As far
as I know, only Chrome has implemented the experimental feature so far. Because of the status as
a `}<ItalicText mdxType="ItalicText">{`“First Public Working Draft”`}</ItalicText>{`, the current specification is subject to change in the future.
We'll just have to wait and see, I guess.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`The new Popover API combined with CSS Anchor Positioning will make our lives as web developers easier and help to
make the web more accessible by default.`}</p>
    <p>{`Despite the still experimental status of the new APIs, you should play around with them and familiarize yourselves with the
new attributes and properties. This way, when cross-browser support arrives, you'll be ready to rumble! 😎`}</p>
    <h2>{`Useful Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/Popover_API"
        }}>{`Popover API (MDN)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://html.spec.whatwg.org/multipage/popover.html#the-popover-attribute"
        }}>{`HTML specification for the popover attribute`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://open-ui.org/components/popover.research.explainer/"
        }}>{`Popover API Explainer (Open UI)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/css-anchor-position-1/"
        }}>{`CSS Anchor Positioning (Draft)`}</a></li>
    </ul>
    <PostUpdateTitle mdxType="PostUpdateTitle">Update on 04/05/2024</PostUpdateTitle>
    <p>{`Revised code examples and explanations to account for changed CSS Anchor Positioning specification
(W3C Working Draft, 26 March 2024) and the implementation in Chrome Canary.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      